var ismobile = '23';
var $window;
var $header;
var $teaser;
var $navbar;
var fadeableContent;
var fadeableFooter;
var $teasertext;
var $teasertextContainer;
var menueMaxScrollHoehe = 146;
var teaserSliderScrollFaktor = 2.5;

var $embedResponsive;

var $logo;
var $realLogo;

var $mainMenueLi;

var md;
var $banner;

var $fbIcon;
var bMobileMenuSmall = false;

var $langSwitcher;

var intersectionObserver;

var $verlinkungHeadlines;

$(document).ready(function () {
    md = new MobileDetect(window.navigator.userAgent);
    $window = $(window);
    $header = $('header');
    $teaser = $('.main__teaser__tease');
    $logo = $header.find('.logo-left');
    $realLogo = $logo.find('img');
    $embedResponsive = $teaser.find('.embed-responsive');
    $banner = $('.banner');
    $fbIcon = $header.find('.facebook-link');
    $teasertextContainer = $('.teaser-text--container');
    $langSwitcher = $header.find('.lang--easy a');

    $navbar = $header.find('.navbar');
    $teasertext = $teaser.find('.teaser-text');

    initOnepager();

    windowResize();
    $window.on('resize', function () {
        windowResize();
        if(!ismobile) {
            // initTeaserSize();
            // setLogoTop();
        }
        // setContentStartOffset();
    });
    $window.on('scroll', function (e) {
        if (!ismobile) {
            animateMenue(e);
        } else {
            animateMobileMenue(e);
        }
        fadeContent();
    });

    initContent();
    adjustAnsprechpartner();
});

function initOnepager() {
    if(document.readyState != "complete") {
        window.setTimeout(initOnepager, 100);
        return false;
    } else {
        onepager.variables.callback = function (data) {
            initContent();
            swapLangSwitcher(data.REX_URL);
        };
    }
}

function swapLangSwitcher(urls) {
    var langType = $langSwitcher.attr('data-lang-type');
    var langCode;
    if (langType === 'normal') {
        langCode = 'de';
    }
    if (langType === 'easy') {
        langCode = 'de_easy';
    }

    if (langCode) {
        $langSwitcher.attr('href', urls[langCode]);
    }
}

function initContent() {
    if(window.IntersectionObserver) {
        intersectionObserver = new IntersectionObserver(function(event) {
            event.forEach(function(entry) {
                if(entry.isIntersecting === true) {
                    entry.target.classList.add('fadeContentIn');
                    intersectionObserver.unobserve(entry.target);
                }
            });
        });
    }

    loadShop();
    initFadeTeaser();
    // initTeaserSize();
    initFadeContent();
    initFlexsliderTeaser();

    initScrollTop();

    if(ismobile) {
        initMobileFunctions();
    }

    if(!intersectionObserver) {
        $window.off('scroll').on('scroll', function (e) {
            fadeContent();
        });
    }
    fadeContent();
    initFontResizer();
    initVerlinkungHeadline();
    adjustVerlinkungHeadline();
}

function lazyLoad() {
    fitNavspaltenHeaders();
    $(window).trigger('scroll');
}

function initFadeContent() {
    fadeableContent = $('.main__content__ajax').find('>.container, >.container-fluid').find('>*').not('.no-fade');
    fadeableFooter = $('#footer').find('>div').not('.no-fade');

    if(!intersectionObserver) {
        $.each(fadeableContent, function (i, v) {
            $(v).data().top = $(v).offset().top;
        });

        $.each(fadeableFooter, function (i, v) {
            $(v).data().top = $(v).offset().top;
        });
    } else {
        $.each(fadeableContent, function(i, v) {
            intersectionObserver.observe(v);
        });
        $.each(fadeableFooter, function(i, v) {
            intersectionObserver.observe(v);
        });
    }
}

function fadeContent() {
    var offset = $window.scrollTop() + $window.height() + 200;
    $.each(fadeableContent, function (i, v) {
        if ($(v).data().top < offset) {
            $(v).addClass('fadeContentIn');
        }
    });
    if (fadeableFooter.data().top < offset) {
        fadeableFooter.addClass('fadeContentIn');
    }
}

function windowResize() {
    if (md.mobile()) {
        if (!ismobile || ismobile === '23') {
            ismobile = true;
            $('.backgroundvideo').html('');
            $('.header').css('transform', '');
        }
    } else {
        if (ismobile || ismobile === '23') {
            ismobile = false;
        }
    }
}

function animateMobileMenue(e) {
    if (!ismobile) return;
    var scrollhoehe = e.currentTarget.scrollY;
    if (scrollhoehe >= 10 && !bMobileMenuSmall) {
        $header.addClass('mobile--small');
        bMobileMenuSmall = true;
    } else if(scrollhoehe < 10 && bMobileMenuSmall) {
        $header.removeClass('mobile--small');
        bMobileMenuSmall = false;
    }
}

function animateMenue(e) {
    if (ismobile) return;
    var scrollhoehe = e.currentTarget.scrollY;

    var headerOffset = scrollhoehe / 4;

    var m = scrollhoehe / 6;
    var bannerHeight = scrollhoehe / 3;
    if(bannerHeight > 500) bannerHeight = 500;
    // var scale = 1*(scrollhoehe/30);
    // if(scale < 1) scale = 1;
    // if(scale > 10) scale = 10;

    if(headerOffset > menueMaxScrollHoehe) headerOffset = menueMaxScrollHoehe;
    $header.css('transform','translateY(-'+headerOffset+'px)');


    var logoHeight = 130 - headerOffset;

    if(logoHeight < 50) logoHeight = 50;
    if(logoHeight > 130) logoHeight = 130;

    $logo.css('height', logoHeight + 'px');
    $realLogo.css('height', logoHeight + 'px');

    var fbIconTop = 15 + headerOffset;
    if(fbIconTop > 70) fbIconTop = 70;
    $fbIcon.css('top', fbIconTop + 'px');

    var navbarTranslateTop = scrollhoehe / 8;
    if(navbarTranslateTop > 23) navbarTranslateTop = 23;
    $navbar.css('transform', 'translateY(-50%) translateY(-'+navbarTranslateTop+'px)');

    if($teaser.hasClass('hideTeaser') === false) {
        m = m * teaserSliderScrollFaktor;
        $teaser.css('transform','translateY('+m+'px)');
        $teaser.find('.flex-control-nav').css('transform','translateY(-'+m+'px)');
        $teasertextContainer.css('transform', 'translateX(-50%) translateY(-'+m+'px)');
        // $banner.css('transform','scaleY('+scale+')');
        $banner.css('padding-top',(100+bannerHeight)+'px');
    }
}

function initKontaktformular() {
    var $frmContact = $('#frmContact');
    var $submitBtn = $('#submitBtn');
    $frmContact.submit(function () {
        var l = Ladda.create(document.querySelector('#submitBtn'));
        l.start();
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: $frmContact.serialize(),
        }).done().then(function (msg) {
            l.stop();
            msg = JSON.parse(msg);
            var tmp = $submitBtn.parent().html();
            $submitBtn.parent().html('<div class="alert alert-' + msg.status + ' text-left fade in">' + msg.text + '</div>');
            if (msg.status === 'success')
                $frmContact[0].reset();
            setTimeout(function () {
                $('.alert').parent().fadeOut(300, function () {
                    $('.alert').parent().html(tmp);
                    $submitBtn.parent().fadeIn(300);
                });
            }, 1500);
        });

        return false;
    });
}

function initMobileFunctions() {
    $('.mobilemenu__button').off('click').on('click', function () {
        $('.mobilemenu').addClass('open');
    });
    $('.mobilemenu__button-close, .mobilemenu').off('click').on('click', function () {
        $('.mobilemenu').removeClass('open');
        $('.mobilemenu__button-close').css('position', 'absolute');
    });
    $('.mobilemenu').on('transitionend', function (e) {
        if(e.originalEvent.propertyName === 'right' && parseInt(getComputedStyle(e.target).right) == 0) {
            $(e.target).find('.mobilemenu__button-close').css('position', 'fixed');
        } else {
            $('.mobilemenu').scrollTop(0);
        }
    });
}

function initFadeTeaser() {
    $teasertext.addClass('show');
    $teasertext.find('span').addClass('show');
}

function fitNavspaltenHeaders() {
    $('.navspalten--listing').each(function(i, row) {
        var maxHeight = 0;
        var $navspalten = $(row).find('.navspalten').find('.navspalten--headline');
        $navspalten.each(function(x, ele) {
            var height = parseInt($(ele).css('height'));
            if(maxHeight < height) maxHeight = height;
        });
        $navspalten.css('height',maxHeight+'px');
    });
}

function initTeaserSize() {
    var h2Offset = 130;
    var height = $window.height() - h2Offset;
    if($teaser.hasClass('main__teaser__tease')) {
        $teaser.css('height', height+'px');
    }
}

function initScrollTop() {
    $('.scrollTop').off('click').on('click', function() {
        $window.scrollTop(0);
    });
}

var disabledLink = function(e) {
    e.preventDefault();
    e.stopPropagation();
}



function loadShop() {
    $('.btn-warenkorb-add').off('click').on('click', function() {
        addArticle($(this));
        return false;
    });

    $('.clearWkorb').off('click').on('click', function() {
        if(confirm('Warenkorb wirklich leeren?')) {
            $.ajax({
                type: 'POST',
                url: window.location.pathname,
                data: {'action': 'reset', 'ajax': 'true'},
            }).done().then(function (msg) {
                $('.warenkorb__product').remove();
                $('.warenkorb__gesamtpreis').html('0,00 €');
            });
        }
    });

    $('.warenkorb__product__amount').off('change keyup').on('change keyup', function() {
        var menge = $(this);
        var gpreis = $(this).parent().parent().children('.warenkorb__product__gesamtpreis');
        var epreis = $(this).parent().parent().children('.warenkorb__product__einzelpreis');

        var tmp = epreis.html();
        tmp = tmp.replace(" €", "");
        tmp = tmp.replace(",", ".");
        tmp = parseFloat(tmp);

        tmp = tmp * parseInt(menge.val());
        tmp = parseFloat(tmp).toFixed(2);
        tmp = tmp.toString();
        tmp = tmp.replace(".", ",");
        var gesamtpreis = tmp + ' €';

        $.ajax({
            type: 'POST',
            url: window.location.pathname,
            data: {'action': 'update', 'articleid': $(this).data('id'), 'amenge': $(this).val(), 'ajax': 'true'},
        }).done().then(function (data) {
            $('.main__content__ajax').html(data);
            initContent();
        });
    });
}

function addArticle(obj) {

    $.ajax({
        type: 'POST',
        url: obj.data('url'),
        data: obj.parent().parent().serialize(),
    }).done().then(function (msg) {
        var a = $('.shoppingcart--items').html();
        a++;
        $('.shoppingcart--items').html(a);
        showArticleAddWindow();
    });
}

function showArticleAddWindow() {
    $('body').append('<div class="modal fade" id="addedToCart" tabindex="-1" role="dialog">\n' +
        '  <div class="modal-dialog" role="document">\n' +
        '    <div class="modal-content">\n' +
        '      <div class="modal-header">\n' +
        '        <h3 class="modal-title">Hmmm... Lecker!</h3>\n' +
        '        <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n' +
        '          <span aria-hidden="true">&times;</span>\n' +
        '        </button>\n' +
        '      </div>\n' +
        '      <div class="modal-body">\n' +
        '        Der Artikel wurde zum Warenkorb hinzugefügt.\n' +
        '      </div>\n' +
        '      <div class="modal-footer">\n' +
        '        <button type="button" class="btn btn-primary" id="weitershoppen">weiter shoppen</button>\n' +
        '        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="zumwarenkorb">zum Warenkorb</button>\n' +
        '      </div>\n' +
        '    </div>\n' +
        '  </div>\n' +
        '</div>');

    $('#addedToCart').modal('toggle');

    $('#weitershoppen').off('click').on('click', function() {
       $('#addedToCart').modal('hide');
    });

    $('#zumwarenkorb').off('click').on('click', function() {
        $('.fa-shopping-bag').parent().trigger('click');
    });
}

function adjustAnsprechpartner() {
    var $rows = $('.persoenliche-beratung--listing');
    if($rows.length == 0) {
        return;
    }
    $rows.each(function(i, v) {
        var $row = $(v);
        var $entries = $row.find('.persoenliche-beratung--entry');
        var maxHeightH3 = [];
        var maxHeightH4 = [];
        var tmpHeightH3 = 0;
        var tmpHeightH4 = 0;
        for(var i = 0; i < $entries.length; i++) {
            var $e = $($entries[i]);
            var row = $e.attr('data-row');
            tmpHeightH3 = $e.find('h3').height();
            if(!maxHeightH3[row] || tmpHeightH3 > maxHeightH3[row]) {
                maxHeightH3[row] = tmpHeightH3;
            }
            tmpHeightH4 = $e.find('h4').height();
            if(!maxHeightH4[row] || tmpHeightH4 > maxHeightH4[row]) {
                maxHeightH4[row] = tmpHeightH4;
            }
        }
        for (var i = 0; i < maxHeightH3.length; i++) {
            var $e = $row.find('.persoenliche-beratung--entry[data-row='+i+']');
            $e.find('h3').css('height', maxHeightH3[i] + 'px');
            $e.find('h4').css('height', maxHeightH4[i] + 'px');
        }
    });
}

function initVerlinkungHeadline() {
    $verlinkungHeadlines = null;
    var $rows = $('.textblock');
    if ($rows.length == 0) {
        return;
    }
    $rows = $rows.filter(function(i, v) {
        return $(v).find('> .textblock-verlinkung').length > 0;
    });
    $verlinkungHeadlines = $rows;
}


function adjustVerlinkungHeadline() {
    if ($verlinkungHeadlines && $verlinkungHeadlines.length > 0) {
        $verlinkungHeadlines.each(function(i, v) {
            var tmpHeight;
            var maxHeight = 0;
            var $row = $(v);
            var $entries = $row.find('.verlinkung__box > h3');
            for (var i = 0; i < $entries.length; ++i) {
                var $e = $($entries[i]);
                tmpHeight = $e.height();
                if (tmpHeight > maxHeight) {
                    maxHeight = tmpHeight;
                }
            }
            $entries.css('height', maxHeight + 'px');
        });
    }
}

var aktFontSize;
function initFontResizer() {
    if(!aktFontSize) {
        aktFontSize = $('article').css('font-size');
        aktFontSize = parseInt(aktFontSize);
    }

    var $smaller = $header.find('.text--zoom-decr');
    var $larger = $header.find('.text--zoom-incr');
    $smaller.off('click').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        aktFontSize--;
        if(aktFontSize < 15) aktFontSize = 15;
        setFontSize(aktFontSize);
    });
    $larger.off('click').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        aktFontSize++;
        if(aktFontSize > 20) aktFontSize = 20;
        setFontSize(aktFontSize);
    });
}

var $article;
function setFontSize(size) {
    if(!$article) {
        $article = $('#main');
    }
    $article.css('font-size', size + 'px');
}
